import React from 'react';
import BannerImg from '../../images/banner.svg';

function Banner() {
    return (
        <div className="lg:container lg:mx-auto lg:px-16   md:container-none  ">
            <div className=" flex  items-center lg:p-24 md:p-12 sm:p-6 pl-6 bg-accent  lg:flex-nowrap md:flex-nowrap sm:flex-nowrap flex-wrap lg:rounded-[32px]">
                <div className='lg:w-3/5   mx-5 '>
                    <h1 className="md:text-5xl lg:text-6xl  py-10  text-4xl  leading-tight text-white font-semibold">One software solution to manage and grow your business</h1>
                    <p className='lg:mt-10  mt-5 md:text-2xl sm:text-sm  text-white'>A unified solution to manage appointments, staffs, shops and customers hassle-free.</p>
                    <div className='mt-10 '>
                        <a
                            className="inline-block w-[250px] overflow-hidden"
                         href='https://play.google.com/store/apps/details?id=com.pms.business&hl=en_SG&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                            <img className='lg:w-[250px]  -m-[16px]' alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' /></a>
                    </div>
                </div>
                <div>
                    <BannerImg className="w-[280px] lg:h-[520px] h-[350px] " />
                </div>
            </div>
        </div>
    );
}

export default Banner;